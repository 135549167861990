@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

.cls-1 {
    fill: #e95e0f;
}

.cls-2 {
    fill: #0090d4;
}

.cls-3 {
    fill: #21314d;
}

.calendar-past-day {
    background:linear-gradient(45deg,#ffffff 49%,#aaa 50%,#ffffff 51%);
}

.rotate-45 {
    --transform-rotate: 45deg;
    transform: rotate(45deg);
}

.group:hover .group-hover\:flex {
    display: flex;
}

.bold {
    font-weight: bold;
}

.semibold {
    font-weight: 600;
}
